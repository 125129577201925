import {PaginationResultSchema} from "@/core/zod/pagination";
import {fallback} from "@tanstack/router-zod-adapter";
import {z} from "zod";
import {CrmResultSchema} from "./crmResult.schema";

export const companyListSearchSchema = z.object({
  page: fallback(z.number(), 1).default(1),
  pageSize: fallback(z.number(), 15).default(15),
  keywords: fallback(z.string(), "").default("").optional(),
  companyId: fallback(z.string(), "").default("").optional(),
});
export type CompanyListSearch = z.infer<typeof companyListSearchSchema>;

export const BelongsToCompanyStore = z.object({
  "website": z.string(),
  "customerUuid": z.string(),
  "id": z.number(),
})

export const BelongsToCrmCompanyUser = z.object({
  "id": z.number(),
  "belongsToCrmUser": z.object({
    "crmUserId": z.number().optional(),
    "name": z.string().optional(),
  }).nullable(),
})

export const HasOneCompanyServiceCompanyStore = z.object({
  "belongsToCompanyStore": BelongsToCompanyStore,
})

export const CompanyItemSchema = z.object({
  "id": z.number(),
  "endTime": z.coerce.date(),
  "startTime": z.coerce.date(),
  "productId": z.number(),
  "companyId": z.number(),
  "companyUserId": z.number(),
  "companyNameZh": z.string(),
  "companyNameEn": z.string(),
  "businessRegistrationNumber": z.string(),
  "hasOneCompanyServiceCompanyStore": HasOneCompanyServiceCompanyStore,
  "belongsToCrmCompanyUser": BelongsToCrmCompanyUser,
  "username": z.string().nullable(),
  "productName": z.string(),
});
export type CompanyItem = z.infer<typeof CompanyItemSchema>;

export const CompanyIndexResultSchema = PaginationResultSchema(z.array(CompanyItemSchema));
export type CompanyIndexResult = z.infer<typeof CompanyIndexResultSchema>;


// 搜索公司
export const SearchCompanyItemSchema = z.object({
  "id": z.number(),
  "nameZh": z.string(),
  "nameEn": z.string(),
  "activeStatus": z.string(),
  "incorporationDate": z.string(),
  "businessRegistrationNumber": z.string(),
});
export type SearchCompanyItem = z.infer<typeof SearchCompanyItemSchema>;

export const SearchCompanyResultSchema = CrmResultSchema(z.array(SearchCompanyItemSchema));
export type SearchCompanyResult = z.infer<typeof SearchCompanyResultSchema>;

// 添加公司
// companyId: number;
// companyUserId: number;
// companyService: Array<CompanyService>;
// workwechatGroupChatId: Array<number>;
// crmUser: CrmUser;
// companyStore: {
//   customerUuid: string;
//   website: string;
// };
// id?: number;

export const CompanyServiceSchema = z.object({
  "productId": z.number(),
  "startTime": z.string(),
  "endTime": z.string(),
})

export const CrmUserSchema = z.object({
  "crmUserId": z.number().optional(),
  "name": z.string().optional(),
})

export const CompanyStoreSchema = z.object({
  "customerUuid": z.string(),
  "website": z.string(),
  "cgsStore": z.string(), "partner": z.string(), "factory": z.string(),
  "storeProductCategoryId": z.array(z.number()).length(2, "类目必须是两级"),
})

export const AddCompanySchema = z.object({
  "companyId": z.number(),
  "companyUserId": z.number(),
  "companyService": z.array(CompanyServiceSchema),
  "workwechatGroupChatId": z.array(z.number()),
  "crmUser": CrmUserSchema.or(z.record(z.never())),
  "companyStore": CompanyStoreSchema,
});
export type AddCompany = z.infer<typeof AddCompanySchema>;

// 更新公司
export const UpdateCompanySchema = AddCompanySchema.omit({companyService: true, companyStore: true})
  .merge(z.object({
    "id": z.number(),
  }))
  .merge(CompanyServiceSchema)
  .merge(z.object({
    "companyStore": CompanyStoreSchema.merge(z.object({id: z.number()})),
  }));
export type UpdateCompany = z.infer<typeof UpdateCompanySchema>;

// 检测公司冲突
export const CheckBeforeAddNaturalPersonSchema = z.object({
  "companyId": z.number(),
});
export type CheckBeforeAddNaturalPerson = z.infer<typeof CheckBeforeAddNaturalPersonSchema>;

// 获取公司信息
export const CompanySummarySchema = z.object({
  "nameZh": z.string(),
  "nameEn": z.string(),
  "companyId": z.number(),
  "businessRegistrationNumber": z.string(),
});
export type CompanySummary = z.infer<typeof CompanySummarySchema>;

export const CompanySummaryResultSchema = CrmResultSchema(CompanySummarySchema);
export type CompanySummaryResult = z.infer<typeof CompanySummaryResultSchema>;

// 公司详情
export const CompanySchema = z.object({
  "id": z.number(),
  "nameZh": z.string(),
  "nameEn": z.string(),
  "activeStatus": z.string(),
  "incorporationDate": z.string(),
  "businessRegistrationNumber": z.string(),
});
export type Company = z.infer<typeof CompanySchema>;

export const CustomerSchema = z.object({
  "isCooperated": z.number(),
});
export type Customer = z.infer<typeof CustomerSchema>;

export const UserSchema = z.object({
  "id": z.number(),
  "name": z.string(),
});
export type User = z.infer<typeof UserSchema>;

export const CompanyDetailSchema = z.object({
  "user": UserSchema.or(z.array(z.never())),
  "customer": CustomerSchema,
  "company": CompanySchema,
});
export type CompanyDetail = z.infer<typeof CompanyDetailSchema>;



import { keywordsSearchSchema, PaginationResultSchema, paginationSchema } from '@/core/zod/pagination';
import { z } from 'zod'
import { CrmResultSchema } from './crmResult.schema';

export const PostsListSearchSchema = keywordsSearchSchema.merge(paginationSchema)
export type PostsListSearch = z.infer<typeof PostsListSearchSchema>;

export const PostListApiSearchSchema = z.object({
  "postCategoryId": z.string().optional(),
}).merge(PostsListSearchSchema);
export type PostListApiSearch = z.infer<typeof PostListApiSearchSchema>;


export const PostListResultItemSchema = z.object({
  "id": z.number(),
  "title": z.string(),
  // "content": z.array(z.any()),
  "postCategoryId": z.number(),
});
export type PostListResultItem = z.infer<typeof PostListResultItemSchema>;

export const PostListResultSchema = PaginationResultSchema(z.array(PostListResultItemSchema));
export type PostListResult = z.infer<typeof PostListResultSchema>;

// 保存文章
export const PostSaveSchema = z.object({
  "title": z.string().min(4, "标题长度不能小于4"),
  "companyTagId": z.array(z.number()).min(1, "公司标签不能为空"),
  "content": z.array(z.any()),
  "postCategoryId": z.number(),
  "startTime": z.string().min(1, "开始时间不能为空"),
  "endTime": z.string().min(1, "结束时间不能为空"),
});
export type PostSave = z.infer<typeof PostSaveSchema>;

// 更新文章
export const PostUpdateSchema = PostSaveSchema.merge(z.object({
  "id": z.number(),
}));
export type PostUpdate = z.infer<typeof PostUpdateSchema>;


// 文章详情
export const BelongsToCompanyTagSchema = z.object({
  "id": z.number(),
  "name": z.string(),
});
export type BelongsToCompanyTag = z.infer<typeof BelongsToCompanyTagSchema>;

export const HasManyCompanyTagPostElementSchema = z.object({
  "id": z.number(),
  "belongsToCompanyTag": BelongsToCompanyTagSchema,
});
export type HasManyCompanyTagPostElement = z.infer<typeof HasManyCompanyTagPostElementSchema>;

export const ItemSchema = z.object({
  "id": z.number(),
  "title": z.string(),
  "postCategoryId": z.number(),
  "content": z.array(z.any()),
  "hasManyCompanyTagPost": z.array(HasManyCompanyTagPostElementSchema),
  "startTime": z.string(),
  "endTime": z.string(),
});
export type Item = z.infer<typeof ItemSchema>;

export const PostReadResultSchema = CrmResultSchema(z.object({
  "item": ItemSchema,
}))
export type PostReadResult = z.infer<typeof PostReadResultSchema>;
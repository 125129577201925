import {z} from 'zod'

type DataZodType = z.ZodArray<any> | z.ZodObject<any>

export function CrmResultOriginSchema<T extends DataZodType>(data: T) {
  return z.object({
    "code": z.number(),
    "msg": z.string(),
    "data": data,
    "url": z.string().optional()
  });
}

export type CrmResultOrigin = z.infer<ReturnType<typeof CrmResultOriginSchema<any>>>

export function CrmResultSchema<T extends DataZodType>(data: T) {
  return CrmResultOriginSchema(data).shape.data
}

export type CrmResult = z.infer<ReturnType<typeof CrmResultSchema<any>>>;
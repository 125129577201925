import { createRootRouteWithContext, Outlet } from "@tanstack/react-router";
import { QueryClient } from "@tanstack/react-query";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { ErrorResult } from "./-components/ErrorResult";

export const Route = createRootRouteWithContext<{
  queryClient: QueryClient;
}>()({
  component: () => (
    <>
      <Outlet />
      {process.env.NODE_ENV === "production" ? null : (
        <TanStackRouterDevtools />
      )}
    </>
  ),
  errorComponent: ({ error }) => <ErrorResult error={error} />,
});


import { PaginationResultSchema } from "@/core/zod/pagination";
import { fallback } from "@tanstack/router-zod-adapter";
import { z } from "zod";

export const ProductSearchSchema = z.object({
  "keywords": z.string().optional(),
  page: fallback(z.number(), 1).default(1),
  size: fallback(z.number(), 15).default(15),
})
export type ProductSearch = z.infer<typeof ProductSearchSchema>;

export const ProductItemSchema = z.object({
  "id": z.number(),
  "name": z.string(),
  "content": z.array(z.any()),
});
export type ProductItem = z.infer<typeof ProductItemSchema>;

export const ProductIndexResultSchema = PaginationResultSchema(z.array(ProductItemSchema));
export type ProductIndexResult = z.infer<typeof ProductIndexResultSchema>;

/**
 * 新增文章分类
 */
export const AddProductSchema = z.object({
  "name": z.string().min(2, "分类名称至少2个字符"),
  "content": z.array(z.any()),
});
export type AddProduct = z.infer<typeof AddProductSchema>;

// 更新文章分类
export const UpdateProductSchema = z.object({
  "id": z.number().min(1),
}).merge(AddProductSchema);
export type UpdateProduct = z.infer<typeof UpdateProductSchema>;
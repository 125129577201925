import { Link, useRouter } from "@tanstack/react-router";
import { Alert, Button, Result } from "antd";

export type ErrorResultProps = {
  error?: Error;
};
export const ErrorResult: React.FC<ErrorResultProps> = (props) => {
  const { history } = useRouter();

  return (
    <Result
      status="error"
      title="发生了一些错误"
      subTitle="请联系开发人员处理"
      extra={[
        <Button
          type="primary"
          key="go-prev"
          onClick={() => {
            history.go(-1);
          }}
        >
          返回上一页
        </Button>,
        <Link key="go-index" to="/dashboard/company/list">
          <Button>首页</Button>
        </Link>,
      ]}
    >
      <div className="desc">
        <Alert
          message="错误详情"
          description={props.error?.message}
          type="error"
          showIcon
        />
      </div>
    </Result>
  );
};

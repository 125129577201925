/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as clientLayoutImport } from './routes/(client)/_layout'
import { Route as adminDashboardImport } from './routes/(admin)/dashboard'
import { Route as clientLayoutIndexImport } from './routes/(client)/_layout/index'
import { Route as adminDashboardIndexImport } from './routes/(admin)/dashboard/index'
import { Route as adminDashboardWechatIndexImport } from './routes/(admin)/dashboard/wechat/index'
import { Route as clientLayoutCompanyCompanyIdImport } from './routes/(client)/_layout/company/$companyId'
import { Route as adminDashboardPostsListImport } from './routes/(admin)/dashboard/posts/list'
import { Route as clientLayoutPostsPostIdIndexImport } from './routes/(client)/_layout/posts/$postId/index'
import { Route as adminDashboardWorkwechatGroupChatListIndexImport } from './routes/(admin)/dashboard/workwechatGroupChat/list/index'
import { Route as adminDashboardProductListIndexImport } from './routes/(admin)/dashboard/product/list/index'
import { Route as adminDashboardCompanyTagListIndexImport } from './routes/(admin)/dashboard/companyTag/list/index'
import { Route as adminDashboardCompanyListIndexImport } from './routes/(admin)/dashboard/company/list/index'
import { Route as adminDashboardPostsListPostCategoryIdImport } from './routes/(admin)/dashboard/posts/list/$postCategoryId'
import { Route as adminDashboardCompanyGroupChatListCompanyIdImport } from './routes/(admin)/dashboard/companyGroupChat/list/$companyId'
import { Route as adminDashboardPostsReadPostCategoryIdPostIdIndexImport } from './routes/(admin)/dashboard/posts/read/$postCategoryId/$postId/index'

// Create Virtual Routes

const clientImport = createFileRoute('/(client)')()
const adminDashboardPostsReadPostCategoryIdNewLazyImport = createFileRoute(
  '/(admin)/dashboard/posts/read/$postCategoryId/new',
)()

// Create/Update Routes

const clientRoute = clientImport.update({
  id: '/(client)',
  getParentRoute: () => rootRoute,
} as any)

const clientLayoutRoute = clientLayoutImport.update({
  id: '/_layout',
  getParentRoute: () => clientRoute,
} as any)

const adminDashboardRoute = adminDashboardImport.update({
  id: '/(admin)/dashboard',
  path: '/dashboard',
  getParentRoute: () => rootRoute,
} as any)

const clientLayoutIndexRoute = clientLayoutIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => clientLayoutRoute,
} as any)

const adminDashboardIndexRoute = adminDashboardIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => adminDashboardRoute,
} as any)

const adminDashboardWechatIndexRoute = adminDashboardWechatIndexImport.update({
  id: '/wechat/',
  path: '/wechat/',
  getParentRoute: () => adminDashboardRoute,
} as any)

const clientLayoutCompanyCompanyIdRoute =
  clientLayoutCompanyCompanyIdImport.update({
    id: '/company/$companyId',
    path: '/company/$companyId',
    getParentRoute: () => clientLayoutRoute,
  } as any)

const adminDashboardPostsListRoute = adminDashboardPostsListImport
  .update({
    id: '/posts/list',
    path: '/posts/list',
    getParentRoute: () => adminDashboardRoute,
  } as any)
  .lazy(() =>
    import('./routes/(admin)/dashboard/posts/list.lazy').then((d) => d.Route),
  )

const clientLayoutPostsPostIdIndexRoute =
  clientLayoutPostsPostIdIndexImport.update({
    id: '/posts/$postId/',
    path: '/posts/$postId/',
    getParentRoute: () => clientLayoutRoute,
  } as any)

const adminDashboardWorkwechatGroupChatListIndexRoute =
  adminDashboardWorkwechatGroupChatListIndexImport
    .update({
      id: '/workwechatGroupChat/list/',
      path: '/workwechatGroupChat/list/',
      getParentRoute: () => adminDashboardRoute,
    } as any)
    .lazy(() =>
      import(
        './routes/(admin)/dashboard/workwechatGroupChat/list/index.lazy'
      ).then((d) => d.Route),
    )

const adminDashboardProductListIndexRoute = adminDashboardProductListIndexImport
  .update({
    id: '/product/list/',
    path: '/product/list/',
    getParentRoute: () => adminDashboardRoute,
  } as any)
  .lazy(() =>
    import('./routes/(admin)/dashboard/product/list/index.lazy').then(
      (d) => d.Route,
    ),
  )

const adminDashboardCompanyTagListIndexRoute =
  adminDashboardCompanyTagListIndexImport
    .update({
      id: '/companyTag/list/',
      path: '/companyTag/list/',
      getParentRoute: () => adminDashboardRoute,
    } as any)
    .lazy(() =>
      import('./routes/(admin)/dashboard/companyTag/list/index.lazy').then(
        (d) => d.Route,
      ),
    )

const adminDashboardCompanyListIndexRoute = adminDashboardCompanyListIndexImport
  .update({
    id: '/company/list/',
    path: '/company/list/',
    getParentRoute: () => adminDashboardRoute,
  } as any)
  .lazy(() =>
    import('./routes/(admin)/dashboard/company/list/index.lazy').then(
      (d) => d.Route,
    ),
  )

const adminDashboardPostsListPostCategoryIdRoute =
  adminDashboardPostsListPostCategoryIdImport
    .update({
      id: '/$postCategoryId',
      path: '/$postCategoryId',
      getParentRoute: () => adminDashboardPostsListRoute,
    } as any)
    .lazy(() =>
      import('./routes/(admin)/dashboard/posts/list/$postCategoryId.lazy').then(
        (d) => d.Route,
      ),
    )

const adminDashboardCompanyGroupChatListCompanyIdRoute =
  adminDashboardCompanyGroupChatListCompanyIdImport
    .update({
      id: '/companyGroupChat/list/$companyId',
      path: '/companyGroupChat/list/$companyId',
      getParentRoute: () => adminDashboardRoute,
    } as any)
    .lazy(() =>
      import(
        './routes/(admin)/dashboard/companyGroupChat/list/$companyId.lazy'
      ).then((d) => d.Route),
    )

const adminDashboardPostsReadPostCategoryIdNewLazyRoute =
  adminDashboardPostsReadPostCategoryIdNewLazyImport
    .update({
      id: '/posts/read/$postCategoryId/new',
      path: '/posts/read/$postCategoryId/new',
      getParentRoute: () => adminDashboardRoute,
    } as any)
    .lazy(() =>
      import(
        './routes/(admin)/dashboard/posts/read/$postCategoryId/new.lazy'
      ).then((d) => d.Route),
    )

const adminDashboardPostsReadPostCategoryIdPostIdIndexRoute =
  adminDashboardPostsReadPostCategoryIdPostIdIndexImport
    .update({
      id: '/posts/read/$postCategoryId/$postId/',
      path: '/posts/read/$postCategoryId/$postId/',
      getParentRoute: () => adminDashboardRoute,
    } as any)
    .lazy(() =>
      import(
        './routes/(admin)/dashboard/posts/read/$postCategoryId/$postId/index.lazy'
      ).then((d) => d.Route),
    )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/(admin)/dashboard': {
      id: '/(admin)/dashboard'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof adminDashboardImport
      parentRoute: typeof rootRoute
    }
    '/(client)': {
      id: '/(client)'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof clientImport
      parentRoute: typeof rootRoute
    }
    '/(client)/_layout': {
      id: '/(client)/_layout'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof clientLayoutImport
      parentRoute: typeof clientRoute
    }
    '/(admin)/dashboard/': {
      id: '/(admin)/dashboard/'
      path: '/'
      fullPath: '/dashboard/'
      preLoaderRoute: typeof adminDashboardIndexImport
      parentRoute: typeof adminDashboardImport
    }
    '/(client)/_layout/': {
      id: '/(client)/_layout/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof clientLayoutIndexImport
      parentRoute: typeof clientLayoutImport
    }
    '/(admin)/dashboard/posts/list': {
      id: '/(admin)/dashboard/posts/list'
      path: '/posts/list'
      fullPath: '/dashboard/posts/list'
      preLoaderRoute: typeof adminDashboardPostsListImport
      parentRoute: typeof adminDashboardImport
    }
    '/(client)/_layout/company/$companyId': {
      id: '/(client)/_layout/company/$companyId'
      path: '/company/$companyId'
      fullPath: '/company/$companyId'
      preLoaderRoute: typeof clientLayoutCompanyCompanyIdImport
      parentRoute: typeof clientLayoutImport
    }
    '/(admin)/dashboard/wechat/': {
      id: '/(admin)/dashboard/wechat/'
      path: '/wechat'
      fullPath: '/dashboard/wechat'
      preLoaderRoute: typeof adminDashboardWechatIndexImport
      parentRoute: typeof adminDashboardImport
    }
    '/(admin)/dashboard/companyGroupChat/list/$companyId': {
      id: '/(admin)/dashboard/companyGroupChat/list/$companyId'
      path: '/companyGroupChat/list/$companyId'
      fullPath: '/dashboard/companyGroupChat/list/$companyId'
      preLoaderRoute: typeof adminDashboardCompanyGroupChatListCompanyIdImport
      parentRoute: typeof adminDashboardImport
    }
    '/(admin)/dashboard/posts/list/$postCategoryId': {
      id: '/(admin)/dashboard/posts/list/$postCategoryId'
      path: '/$postCategoryId'
      fullPath: '/dashboard/posts/list/$postCategoryId'
      preLoaderRoute: typeof adminDashboardPostsListPostCategoryIdImport
      parentRoute: typeof adminDashboardPostsListImport
    }
    '/(admin)/dashboard/company/list/': {
      id: '/(admin)/dashboard/company/list/'
      path: '/company/list'
      fullPath: '/dashboard/company/list'
      preLoaderRoute: typeof adminDashboardCompanyListIndexImport
      parentRoute: typeof adminDashboardImport
    }
    '/(admin)/dashboard/companyTag/list/': {
      id: '/(admin)/dashboard/companyTag/list/'
      path: '/companyTag/list'
      fullPath: '/dashboard/companyTag/list'
      preLoaderRoute: typeof adminDashboardCompanyTagListIndexImport
      parentRoute: typeof adminDashboardImport
    }
    '/(admin)/dashboard/product/list/': {
      id: '/(admin)/dashboard/product/list/'
      path: '/product/list'
      fullPath: '/dashboard/product/list'
      preLoaderRoute: typeof adminDashboardProductListIndexImport
      parentRoute: typeof adminDashboardImport
    }
    '/(admin)/dashboard/workwechatGroupChat/list/': {
      id: '/(admin)/dashboard/workwechatGroupChat/list/'
      path: '/workwechatGroupChat/list'
      fullPath: '/dashboard/workwechatGroupChat/list'
      preLoaderRoute: typeof adminDashboardWorkwechatGroupChatListIndexImport
      parentRoute: typeof adminDashboardImport
    }
    '/(client)/_layout/posts/$postId/': {
      id: '/(client)/_layout/posts/$postId/'
      path: '/posts/$postId'
      fullPath: '/posts/$postId'
      preLoaderRoute: typeof clientLayoutPostsPostIdIndexImport
      parentRoute: typeof clientLayoutImport
    }
    '/(admin)/dashboard/posts/read/$postCategoryId/new': {
      id: '/(admin)/dashboard/posts/read/$postCategoryId/new'
      path: '/posts/read/$postCategoryId/new'
      fullPath: '/dashboard/posts/read/$postCategoryId/new'
      preLoaderRoute: typeof adminDashboardPostsReadPostCategoryIdNewLazyImport
      parentRoute: typeof adminDashboardImport
    }
    '/(admin)/dashboard/posts/read/$postCategoryId/$postId/': {
      id: '/(admin)/dashboard/posts/read/$postCategoryId/$postId/'
      path: '/posts/read/$postCategoryId/$postId'
      fullPath: '/dashboard/posts/read/$postCategoryId/$postId'
      preLoaderRoute: typeof adminDashboardPostsReadPostCategoryIdPostIdIndexImport
      parentRoute: typeof adminDashboardImport
    }
  }
}

// Create and export the route tree

interface adminDashboardPostsListRouteChildren {
  adminDashboardPostsListPostCategoryIdRoute: typeof adminDashboardPostsListPostCategoryIdRoute
}

const adminDashboardPostsListRouteChildren: adminDashboardPostsListRouteChildren =
  {
    adminDashboardPostsListPostCategoryIdRoute:
      adminDashboardPostsListPostCategoryIdRoute,
  }

const adminDashboardPostsListRouteWithChildren =
  adminDashboardPostsListRoute._addFileChildren(
    adminDashboardPostsListRouteChildren,
  )

interface adminDashboardRouteChildren {
  adminDashboardIndexRoute: typeof adminDashboardIndexRoute
  adminDashboardPostsListRoute: typeof adminDashboardPostsListRouteWithChildren
  adminDashboardWechatIndexRoute: typeof adminDashboardWechatIndexRoute
  adminDashboardCompanyGroupChatListCompanyIdRoute: typeof adminDashboardCompanyGroupChatListCompanyIdRoute
  adminDashboardCompanyListIndexRoute: typeof adminDashboardCompanyListIndexRoute
  adminDashboardCompanyTagListIndexRoute: typeof adminDashboardCompanyTagListIndexRoute
  adminDashboardProductListIndexRoute: typeof adminDashboardProductListIndexRoute
  adminDashboardWorkwechatGroupChatListIndexRoute: typeof adminDashboardWorkwechatGroupChatListIndexRoute
  adminDashboardPostsReadPostCategoryIdNewLazyRoute: typeof adminDashboardPostsReadPostCategoryIdNewLazyRoute
  adminDashboardPostsReadPostCategoryIdPostIdIndexRoute: typeof adminDashboardPostsReadPostCategoryIdPostIdIndexRoute
}

const adminDashboardRouteChildren: adminDashboardRouteChildren = {
  adminDashboardIndexRoute: adminDashboardIndexRoute,
  adminDashboardPostsListRoute: adminDashboardPostsListRouteWithChildren,
  adminDashboardWechatIndexRoute: adminDashboardWechatIndexRoute,
  adminDashboardCompanyGroupChatListCompanyIdRoute:
    adminDashboardCompanyGroupChatListCompanyIdRoute,
  adminDashboardCompanyListIndexRoute: adminDashboardCompanyListIndexRoute,
  adminDashboardCompanyTagListIndexRoute:
    adminDashboardCompanyTagListIndexRoute,
  adminDashboardProductListIndexRoute: adminDashboardProductListIndexRoute,
  adminDashboardWorkwechatGroupChatListIndexRoute:
    adminDashboardWorkwechatGroupChatListIndexRoute,
  adminDashboardPostsReadPostCategoryIdNewLazyRoute:
    adminDashboardPostsReadPostCategoryIdNewLazyRoute,
  adminDashboardPostsReadPostCategoryIdPostIdIndexRoute:
    adminDashboardPostsReadPostCategoryIdPostIdIndexRoute,
}

const adminDashboardRouteWithChildren = adminDashboardRoute._addFileChildren(
  adminDashboardRouteChildren,
)

interface clientLayoutRouteChildren {
  clientLayoutIndexRoute: typeof clientLayoutIndexRoute
  clientLayoutCompanyCompanyIdRoute: typeof clientLayoutCompanyCompanyIdRoute
  clientLayoutPostsPostIdIndexRoute: typeof clientLayoutPostsPostIdIndexRoute
}

const clientLayoutRouteChildren: clientLayoutRouteChildren = {
  clientLayoutIndexRoute: clientLayoutIndexRoute,
  clientLayoutCompanyCompanyIdRoute: clientLayoutCompanyCompanyIdRoute,
  clientLayoutPostsPostIdIndexRoute: clientLayoutPostsPostIdIndexRoute,
}

const clientLayoutRouteWithChildren = clientLayoutRoute._addFileChildren(
  clientLayoutRouteChildren,
)

interface clientRouteChildren {
  clientLayoutRoute: typeof clientLayoutRouteWithChildren
}

const clientRouteChildren: clientRouteChildren = {
  clientLayoutRoute: clientLayoutRouteWithChildren,
}

const clientRouteWithChildren =
  clientRoute._addFileChildren(clientRouteChildren)

export interface FileRoutesByFullPath {
  '/dashboard': typeof adminDashboardRouteWithChildren
  '/': typeof clientLayoutIndexRoute
  '/dashboard/': typeof adminDashboardIndexRoute
  '/dashboard/posts/list': typeof adminDashboardPostsListRouteWithChildren
  '/company/$companyId': typeof clientLayoutCompanyCompanyIdRoute
  '/dashboard/wechat': typeof adminDashboardWechatIndexRoute
  '/dashboard/companyGroupChat/list/$companyId': typeof adminDashboardCompanyGroupChatListCompanyIdRoute
  '/dashboard/posts/list/$postCategoryId': typeof adminDashboardPostsListPostCategoryIdRoute
  '/dashboard/company/list': typeof adminDashboardCompanyListIndexRoute
  '/dashboard/companyTag/list': typeof adminDashboardCompanyTagListIndexRoute
  '/dashboard/product/list': typeof adminDashboardProductListIndexRoute
  '/dashboard/workwechatGroupChat/list': typeof adminDashboardWorkwechatGroupChatListIndexRoute
  '/posts/$postId': typeof clientLayoutPostsPostIdIndexRoute
  '/dashboard/posts/read/$postCategoryId/new': typeof adminDashboardPostsReadPostCategoryIdNewLazyRoute
  '/dashboard/posts/read/$postCategoryId/$postId': typeof adminDashboardPostsReadPostCategoryIdPostIdIndexRoute
}

export interface FileRoutesByTo {
  '/dashboard': typeof adminDashboardIndexRoute
  '/': typeof clientLayoutIndexRoute
  '/dashboard/posts/list': typeof adminDashboardPostsListRouteWithChildren
  '/company/$companyId': typeof clientLayoutCompanyCompanyIdRoute
  '/dashboard/wechat': typeof adminDashboardWechatIndexRoute
  '/dashboard/companyGroupChat/list/$companyId': typeof adminDashboardCompanyGroupChatListCompanyIdRoute
  '/dashboard/posts/list/$postCategoryId': typeof adminDashboardPostsListPostCategoryIdRoute
  '/dashboard/company/list': typeof adminDashboardCompanyListIndexRoute
  '/dashboard/companyTag/list': typeof adminDashboardCompanyTagListIndexRoute
  '/dashboard/product/list': typeof adminDashboardProductListIndexRoute
  '/dashboard/workwechatGroupChat/list': typeof adminDashboardWorkwechatGroupChatListIndexRoute
  '/posts/$postId': typeof clientLayoutPostsPostIdIndexRoute
  '/dashboard/posts/read/$postCategoryId/new': typeof adminDashboardPostsReadPostCategoryIdNewLazyRoute
  '/dashboard/posts/read/$postCategoryId/$postId': typeof adminDashboardPostsReadPostCategoryIdPostIdIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/(admin)/dashboard': typeof adminDashboardRouteWithChildren
  '/(client)': typeof clientRouteWithChildren
  '/(client)/_layout': typeof clientLayoutRouteWithChildren
  '/(admin)/dashboard/': typeof adminDashboardIndexRoute
  '/(client)/_layout/': typeof clientLayoutIndexRoute
  '/(admin)/dashboard/posts/list': typeof adminDashboardPostsListRouteWithChildren
  '/(client)/_layout/company/$companyId': typeof clientLayoutCompanyCompanyIdRoute
  '/(admin)/dashboard/wechat/': typeof adminDashboardWechatIndexRoute
  '/(admin)/dashboard/companyGroupChat/list/$companyId': typeof adminDashboardCompanyGroupChatListCompanyIdRoute
  '/(admin)/dashboard/posts/list/$postCategoryId': typeof adminDashboardPostsListPostCategoryIdRoute
  '/(admin)/dashboard/company/list/': typeof adminDashboardCompanyListIndexRoute
  '/(admin)/dashboard/companyTag/list/': typeof adminDashboardCompanyTagListIndexRoute
  '/(admin)/dashboard/product/list/': typeof adminDashboardProductListIndexRoute
  '/(admin)/dashboard/workwechatGroupChat/list/': typeof adminDashboardWorkwechatGroupChatListIndexRoute
  '/(client)/_layout/posts/$postId/': typeof clientLayoutPostsPostIdIndexRoute
  '/(admin)/dashboard/posts/read/$postCategoryId/new': typeof adminDashboardPostsReadPostCategoryIdNewLazyRoute
  '/(admin)/dashboard/posts/read/$postCategoryId/$postId/': typeof adminDashboardPostsReadPostCategoryIdPostIdIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/dashboard'
    | '/'
    | '/dashboard/'
    | '/dashboard/posts/list'
    | '/company/$companyId'
    | '/dashboard/wechat'
    | '/dashboard/companyGroupChat/list/$companyId'
    | '/dashboard/posts/list/$postCategoryId'
    | '/dashboard/company/list'
    | '/dashboard/companyTag/list'
    | '/dashboard/product/list'
    | '/dashboard/workwechatGroupChat/list'
    | '/posts/$postId'
    | '/dashboard/posts/read/$postCategoryId/new'
    | '/dashboard/posts/read/$postCategoryId/$postId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/dashboard'
    | '/'
    | '/dashboard/posts/list'
    | '/company/$companyId'
    | '/dashboard/wechat'
    | '/dashboard/companyGroupChat/list/$companyId'
    | '/dashboard/posts/list/$postCategoryId'
    | '/dashboard/company/list'
    | '/dashboard/companyTag/list'
    | '/dashboard/product/list'
    | '/dashboard/workwechatGroupChat/list'
    | '/posts/$postId'
    | '/dashboard/posts/read/$postCategoryId/new'
    | '/dashboard/posts/read/$postCategoryId/$postId'
  id:
    | '__root__'
    | '/(admin)/dashboard'
    | '/(client)'
    | '/(client)/_layout'
    | '/(admin)/dashboard/'
    | '/(client)/_layout/'
    | '/(admin)/dashboard/posts/list'
    | '/(client)/_layout/company/$companyId'
    | '/(admin)/dashboard/wechat/'
    | '/(admin)/dashboard/companyGroupChat/list/$companyId'
    | '/(admin)/dashboard/posts/list/$postCategoryId'
    | '/(admin)/dashboard/company/list/'
    | '/(admin)/dashboard/companyTag/list/'
    | '/(admin)/dashboard/product/list/'
    | '/(admin)/dashboard/workwechatGroupChat/list/'
    | '/(client)/_layout/posts/$postId/'
    | '/(admin)/dashboard/posts/read/$postCategoryId/new'
    | '/(admin)/dashboard/posts/read/$postCategoryId/$postId/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  adminDashboardRoute: typeof adminDashboardRouteWithChildren
  clientRoute: typeof clientRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  adminDashboardRoute: adminDashboardRouteWithChildren,
  clientRoute: clientRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/(admin)/dashboard",
        "/(client)"
      ]
    },
    "/(admin)/dashboard": {
      "filePath": "(admin)/dashboard.tsx",
      "children": [
        "/(admin)/dashboard/",
        "/(admin)/dashboard/posts/list",
        "/(admin)/dashboard/wechat/",
        "/(admin)/dashboard/companyGroupChat/list/$companyId",
        "/(admin)/dashboard/company/list/",
        "/(admin)/dashboard/companyTag/list/",
        "/(admin)/dashboard/product/list/",
        "/(admin)/dashboard/workwechatGroupChat/list/",
        "/(admin)/dashboard/posts/read/$postCategoryId/new",
        "/(admin)/dashboard/posts/read/$postCategoryId/$postId/"
      ]
    },
    "/(client)": {
      "filePath": "(client)",
      "children": [
        "/(client)/_layout"
      ]
    },
    "/(client)/_layout": {
      "filePath": "(client)/_layout.tsx",
      "parent": "/(client)",
      "children": [
        "/(client)/_layout/",
        "/(client)/_layout/company/$companyId",
        "/(client)/_layout/posts/$postId/"
      ]
    },
    "/(admin)/dashboard/": {
      "filePath": "(admin)/dashboard/index.tsx",
      "parent": "/(admin)/dashboard"
    },
    "/(client)/_layout/": {
      "filePath": "(client)/_layout/index.tsx",
      "parent": "/(client)/_layout"
    },
    "/(admin)/dashboard/posts/list": {
      "filePath": "(admin)/dashboard/posts/list.tsx",
      "parent": "/(admin)/dashboard",
      "children": [
        "/(admin)/dashboard/posts/list/$postCategoryId"
      ]
    },
    "/(client)/_layout/company/$companyId": {
      "filePath": "(client)/_layout/company/$companyId.tsx",
      "parent": "/(client)/_layout"
    },
    "/(admin)/dashboard/wechat/": {
      "filePath": "(admin)/dashboard/wechat/index.tsx",
      "parent": "/(admin)/dashboard"
    },
    "/(admin)/dashboard/companyGroupChat/list/$companyId": {
      "filePath": "(admin)/dashboard/companyGroupChat/list/$companyId.tsx",
      "parent": "/(admin)/dashboard"
    },
    "/(admin)/dashboard/posts/list/$postCategoryId": {
      "filePath": "(admin)/dashboard/posts/list/$postCategoryId.tsx",
      "parent": "/(admin)/dashboard/posts/list"
    },
    "/(admin)/dashboard/company/list/": {
      "filePath": "(admin)/dashboard/company/list/index.tsx",
      "parent": "/(admin)/dashboard"
    },
    "/(admin)/dashboard/companyTag/list/": {
      "filePath": "(admin)/dashboard/companyTag/list/index.tsx",
      "parent": "/(admin)/dashboard"
    },
    "/(admin)/dashboard/product/list/": {
      "filePath": "(admin)/dashboard/product/list/index.tsx",
      "parent": "/(admin)/dashboard"
    },
    "/(admin)/dashboard/workwechatGroupChat/list/": {
      "filePath": "(admin)/dashboard/workwechatGroupChat/list/index.tsx",
      "parent": "/(admin)/dashboard"
    },
    "/(client)/_layout/posts/$postId/": {
      "filePath": "(client)/_layout/posts/$postId/index.tsx",
      "parent": "/(client)/_layout"
    },
    "/(admin)/dashboard/posts/read/$postCategoryId/new": {
      "filePath": "(admin)/dashboard/posts/read/$postCategoryId/new.lazy.tsx",
      "parent": "/(admin)/dashboard"
    },
    "/(admin)/dashboard/posts/read/$postCategoryId/$postId/": {
      "filePath": "(admin)/dashboard/posts/read/$postCategoryId/$postId/index.tsx",
      "parent": "/(admin)/dashboard"
    }
  }
}
ROUTE_MANIFEST_END */

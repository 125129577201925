
import { CrmResultSchema } from '@/httpClient/crmClient/zod/crmResult.schema';
import { fallback } from '@tanstack/router-zod-adapter';
import { z } from 'zod'

export const paginationSchema = z.object({
  page: fallback(z.number(), 1).default(1).optional(),
  size: fallback(z.number(), 15).default(15).optional(),
});

export const Pagination = paginationSchema


// keywords
export const keywordsSearchSchema = z.object({
  keywords: fallback(z.string(), '').default('').optional(),
});
export type KeywordsSearch = z.infer<typeof keywordsSearchSchema>;


// 响应
export function PaginationResultSchema<T extends z.ZodObject<any>>(data: z.ZodArray<T>) {
  return CrmResultSchema(
    z.object({
      "total": z.number(),
      "perPage": z.number(),
      "currentPage": z.number().or(z.string()),
      "lastPage": z.number(),
      "data": data
    })
  )
}


import {z} from 'zod'
import {fallback} from "@tanstack/router-zod-adapter";
import {PaginationResultSchema} from "@/core/zod/pagination.ts";

/** 筛选关联公司的个数， 1-无, 2-有, 3-多个 */
export enum RelatedCompanyEnum {
  All = '',
  /** 无 */
  None = '1',
  /** 有 */
  One = '2',
  /** 多个 */
  Multiple = '3',
}

/** 筛选关联公司的个数， 1-无, 2-有, 3-多个 中文映射 */
export const RelatedCompanyEnumMap = {
  /** 无 */
  [RelatedCompanyEnum.None]: '无',
  /** 有 */
  [RelatedCompanyEnum.One]: '有',
  /** 多个 */
  [RelatedCompanyEnum.Multiple]: '多个',
}

/** 筛选有无群名， 1-无, 2-有 */
export enum NameStatusEnum {
  All = '',
  /** 无 */
  None = '1',
  /** 有 */
  One = '2',
}

/** 筛选有无群名， 1-无, 2-有 中文映射 */
export const NameStatusEnumMap = {
  /** 无 */
  [NameStatusEnum.None]: '无',
  /** 有 */
  [NameStatusEnum.One]: '有',
}

export const GetWorkwechatGroupChatListSearch = z.object({
  page: fallback(z.number(), 1).default(1),
  pageSize: fallback(z.number(), 15).default(15),
  keywords: fallback(z.string(), "").default(""),
  /** 筛选关联公司的个数， 1-无, 2-有, 3-多个 */
  relatedCompany: fallback(z.nativeEnum(RelatedCompanyEnum).optional(), undefined).default(RelatedCompanyEnum.All),
  /** 筛选有无群名， 1-无, 2-有 */
  nameStatus: fallback(z.nativeEnum(NameStatusEnum).optional(), undefined).default(NameStatusEnum.All),
})
export type GetWorkwechatGroupChatListSearch = z.infer<typeof GetWorkwechatGroupChatListSearch>

export const BelongsToCompanySchema = z.object({
  "nameZh": z.string(),
  "nameEn": z.string(),
  "companyId": z.number(),
  "businessRegistrationNumber": z.string(),
});
export type BelongsToCompany = z.infer<typeof BelongsToCompanySchema>;

export const HasCompanyWorkwechatGroupChatSchema = z.object({
  "id": z.number(),
  "belongsToCompany": BelongsToCompanySchema,
});
export type HasCompanyWorkwechatGroupChat = z.infer<typeof HasCompanyWorkwechatGroupChatSchema>;

export const WorkwechatGroupChatElementSchema = z.object({
  "id": z.number(),
  "name": z.string(),
  "createTime": z.coerce.date(),
  "hasCompanyWorkwechatGroupChat": z.array(HasCompanyWorkwechatGroupChatSchema),
});
export type WorkwechatGroupChatElement = z.infer<typeof WorkwechatGroupChatElementSchema>;

export const GetWorkwechatGroupChatListSchema = z.array(WorkwechatGroupChatElementSchema);
export type GetWorkwechatGroupChatList = z.infer<typeof GetWorkwechatGroupChatListSchema>;

export const GetWorkwechatGroupChatListResult = PaginationResultSchema(GetWorkwechatGroupChatListSchema);
export type GetWorkwechatGroupChatListResult = z.infer<typeof GetWorkwechatGroupChatListResult>
